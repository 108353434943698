import React, { FC, useState, useEffect } from 'react';
import MemberModal from 'src/components/MemberModal/MemberModal';
import Pagination from 'src/components/Pagination/Paginations';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { Member } from 'src/utils/types';
import {
  useGetAllMembersQuery,
  useRemoveFolderMutation,
  useRemoveFilesMutation,
  useRemoveMemberMutation,
} from '../../generated/apolloComponents';
import Loading from '../../components/Loading/Loading';

const Members: FC = () => {
  const {
    data: members,
    loading,
    refetch,
  } = useGetAllMembersQuery({
    variables: {
      slug: 'all',
    },
  });
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMembers, setCurrentMembers] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [editMember, setEditMember] = useState<Member | undefined>();
  const [profilesPerPage] = useState(10);
  const [innerLoading, setInnerLoading] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<
    Array<String | undefined>
  >([]);
  const [removeFolder] = useRemoveFolderMutation();
  const [removeMember] = useRemoveMemberMutation();
  const [removeFiles] = useRemoveFilesMutation();

  const handleDropDown = () => {
    setOpenDropdown(!openDropdown);
  };
  const handlePageChange = (selected: number) => {
    if (selected !== currentPage) {
      setCurrentPage(selected);
    }
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (
      members &&
      currentPage !== Math.ceil(members.getAllMembers.length / profilesPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleEditClose = () => {
    setIsOpen(false);
    setEditMember(undefined);
  };

  useEffect(() => {
    if (members) {
      const indexOfLastPost = currentPage * profilesPerPage;
      const indexOfFirstPost = indexOfLastPost - profilesPerPage;
      const currentData = members.getAllMembers.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentMembers(currentData);
    }
  }, [currentPage, members, profilesPerPage]);

  const handleSelected = (e: any, id: string) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedMembers((current) => [...current, id]);
    }
    if (
      !checked &&
      selectedMembers.length > 0 &&
      selectedMembers.includes(id)
    ) {
      const filteredSelected = selectedMembers.filter(
        (member) => member !== id
      );
      setSelectedMembers(filteredSelected);
    }
  };

  const handleDeleteMembers = async () => {
    setInnerLoading(true);
    for (const id of selectedMembers) {
      const selectedProps = members?.getAllMembers
        .filter((member) => member.id === id)
        .map((selected) => ({
          firstName: selected.firstName,
          lastName: selected.lastName,
          imgUrl: selected.imgUrl,
          logos: selected.logos,
        }));
      await removeMember({
        variables: {
          // @ts-ignore
          id,
        },
      });
      if (selectedProps && selectedProps?.length > 0) {
        const folderName = `Members/${selectedProps[0]?.firstName}_${selectedProps[0]?.lastName}`;
        const publicIDRegex = /([^\/]+)(?=\.\w+$)/;
        const ids: string[] = [];
        if (selectedProps[0]?.logos) {
          for (const logo of selectedProps[0]?.logos) {
            const matching = logo.match(publicIDRegex);
            if (matching && matching[0])
              ids.push(`${folderName}/${matching[0]}`);
          }
        }
        if (selectedProps[0]?.imgUrl) {
          const matching = selectedProps[0]?.imgUrl.match(publicIDRegex);
          if (matching && matching[0]) ids.push(`${folderName}/${matching[0]}`);
        }

        if (ids.length > 0) {
          await removeFiles({
            variables: {
              publicIds: ids,
            },
          });
        }

        await removeFolder({
          variables: {
            folderName: `Members/${selectedProps[0]?.firstName}_${selectedProps[0]?.lastName}`,
          },
        });
      }
    }
    setOpenDropdown(!openDropdown);
    setSelectedMembers([]);
    refetch();
    setInnerLoading(false);
  };

  if (loading || innerLoading) {
    return <Loading />;
  }

  return (
    <Sidebar>
      <h1 className="text-3xl font-bold">Общност</h1>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {!isOpen && (
          <>
            <div className="flex items-center justify-between pb-4 bg-white">
              <div>
                <button
                  id="dropdownActionButton"
                  onClick={handleDropDown}
                  className="mt-5 ml-5 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5"
                  type="button"
                >
                  <span className="sr-only">Action button</span>
                  Опции
                  <svg
                    className="w-3 h-3 ml-2"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {openDropdown && (
                  <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute">
                    <div className="py-1">
                      <button
                        onClick={handleDeleteMembers}
                        disabled={selectedMembers.length < 1}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Премахни
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="mt-5 mr-5 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-bold rounded-lg text-base px-3 py-1.5"
              >
                Добави нов член
              </button>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="hidden flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      ></input>
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Имена
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Категория
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Компания
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Редактирай
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentMembers &&
                  currentMembers.map((profile: any, i: number) => (
                    <tr className="bg-white border-b hover:bg-gray-50" key={i}>
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id={`checkbox-${i}`}
                            type="checkbox"
                            onChange={(e) => {
                              handleSelected(e, profile.id);
                            }}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          ></input>
                          <label htmlFor={`checkbox-${i}`} className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
                      >
                        <div className="pl-3">
                          <div className="text-base font-semibold">{`${profile.firstName} ${profile.lastName}`}</div>
                        </div>
                      </th>
                      <td className="px-6 py-4">{profile.memberCategory}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          {profile.company}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          type="button"
                          className="font-medium text-[rgba(255,124,92,1)] hover:underline"
                          onClick={() => {
                            setIsOpen(true);
                            setEditMember(profile);
                          }}
                        >
                          Коригирай
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={profilesPerPage}
              totalItems={members ? members.getAllMembers.length : 1}
              handlePageChange={handlePageChange}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
            />
          </>
        )}
        {isOpen && (
          <MemberModal
            handleClose={handleEditClose}
            refetch={refetch}
            isEdit={!!editMember}
            memberData={editMember}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default Members;
