import React, { FC } from 'react';
import ReactLoading from 'react-loading';

const Loading: FC = () => (
  <div className="w-full h-full fixed flex items-center justify-center top-0 left-0 bg-gray-200">
    <ReactLoading type="spin" color="#439BDA" height={'60px'} width={'60px'} />
  </div>
);

export default Loading;
