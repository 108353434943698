import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGetAllPollsQuery } from '../../generated/apolloComponents';
import Edit from '../../assets/images/edit_btn.png';
import Statistics from '../../assets/images/statistics.png';

const Polls: FC = () => {
  const history = useNavigate();
  const { data, loading } = useGetAllPollsQuery();
  if (loading) {
    return <Loading />;
  }
  return (
    <Sidebar>
      <div>
        <h1 className="text-3xl font-bold">Анкети</h1>
        <div className="my-2 flex sm:flex-row flex-col justify-end">
          <div className="flex justify-end items-center ml-3">
            <Link
              className="bg-primaryBlack text-white px-4 py-2 rounded"
              to="/new-poll"
            >
              Нова анкета
            </Link>
          </div>
        </div>
        {data && (
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Име на анкета
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Автор
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Статус
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Действия
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.getAllPolls.map((poll) => (
                    <tr key={poll.slug}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap font-bold">
                          {poll.name} / {poll.slug}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {' '}
                          {poll.owner.firstName} {poll.owner.lastName}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {poll.isPublished ? (
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <span className="relative">Публична</span>
                          </span>
                        ) : (
                          <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                            <span
                              aria-hidden="true"
                              className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            ></span>
                            <span className="relative">Чернова</span>
                          </span>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center flex-row">
                          <img
                            src={Edit}
                            onClick={() =>
                              history(`/poll-creator/${poll.slug}`)
                            }
                            alt="Edit Poll"
                            className="cursor-pointer mr-3"
                          />
                          <img
                            src={Statistics}
                            onClick={() =>
                              history(`/poll-statistics/${poll.slug}`)
                            }
                            alt="Poll Statistics"
                            className="cursor-pointer"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default Polls;
