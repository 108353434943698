import React, { FC } from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import {
  useChangeUserStatusMutation,
  User,
} from '../../generated/apolloComponents';

type BlockAdminModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  admin: Pick<
    User,
    'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'status'
  >;
  refetch: () => void;
};
const BlockAdminModal: FC<BlockAdminModalProps> = ({
  modalIsOpen,
  setIsOpen,
  admin,
  refetch,
}: BlockAdminModalProps) => {
  const [changeStatus] = useChangeUserStatusMutation();
  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      className="NewCategoryModal"
      overlayClassName="NewCategoryModalOverlay"
    >
      <div className="relative flex justify-center py-3 bg-gray-200 rounded-t">
        <span className="text-md mr-5 sm:mr-0 md:text-xl text-center sm:text-left">
          {admin.status
            ? `Блокиране на ${admin.firstName} ${admin.lastName}`
            : `Активиране на ${admin.firstName} ${admin.lastName}`}
        </span>
        <CloseIcon
          onClick={() => setIsOpen(false)}
          className="absolute right-5 top-15 cursor-pointer"
        />
      </div>
      <div className="p-2 sm:p-6">
        <p className="text-center">
          Сигурни ли сте, че искате да{' '}
          {admin.status ? `блокирате` : `активирате`} администратор{' '}
          {admin.firstName} {admin.lastName}
        </p>
        <div className="flex mt-8 mb-6">
          <button
            className="p-3 mx-auto block bg-primaryBlack rounded-tr-none rounded-br-none hover:bg-summerBlue text-white font-medium text-lg rounded w-full disabled:opacity-25"
            onClick={() => setIsOpen(false)}
          >
            Не
          </button>
          <button
            onClick={() => {
              changeStatus({ variables: { email: admin.email } });
              setIsOpen(false);
              refetch();
            }}
            className="p-3 mx-auto block bg-primaryBlack rounded-tl-none rounded-bl-none hover:bg-summerBlue text-white font-medium text-lg rounded w-full disabled:opacity-25"
          >
            Да
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default BlockAdminModal;
