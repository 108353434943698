import React, { FC } from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import {
  useDraftPollMutation,
  usePublishPollMutation,
} from '../../generated/apolloComponents';

type PublishPollModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  pollId: string;
  published: () => void;
  drafted: () => void;
  refetch: () => void;
  isPublised: boolean;
};

const PublishPollModal: FC<PublishPollModalProps> = ({
  modalIsOpen,
  setIsOpen,
  pollId,
  published,
  isPublised,
  drafted,
  refetch,
}: PublishPollModalProps) => {
  const [publishPoll, { loading: publishLoading }] = usePublishPollMutation();
  const [saveDraft, { loading: draftLoading }] = useDraftPollMutation();
  const publish = async () => {
    await publishPoll({
      variables: {
        pollId: pollId,
      },
    });
    setIsOpen(false);
    published();
    refetch();
  };

  const saveAsDraft = async () => {
    await saveDraft({
      variables: {
        pollId: pollId,
      },
    });
    setIsOpen(false);
    drafted();
    refetch();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      className="PublishPollModal"
      overlayClassName="PublishPollModalOverlay"
    >
      <div className="relative flex justify-center py-3 bg-gray-200 rounded-t">
        <span className="text-md mr-5 sm:mr-0 md:text-xl text-center sm:text-left">
          {isPublised ? 'Запазване като чернова' : 'Публикуване на анкета'}
        </span>
        <CloseIcon
          onClick={() => setIsOpen(false)}
          className="absolute right-5 top-15 cursor-pointer"
        />
      </div>
      <div className="p-2 sm:p-6">
        {isPublised ? (
          <p className="text-center">
            Тази анкета е публична, искате ли да я запазите като чернова?
            <br /> ( чернови обявите няма да бъдат видими в платформата )
          </p>
        ) : (
          <p className="text-center">
            Сигурни ли сте, че искате да публикувате анкетата?
          </p>
        )}

        <div className="flex mt-8 mb-6">
          <button
            className="p-3 mx-auto block bg-primaryBlack rounded-tr-none rounded-br-none hover:bg-summerBlue text-white font-medium text-lg rounded w-full disabled:opacity-25"
            onClick={() => setIsOpen(false)}
            disabled={publishLoading || draftLoading}
          >
            Не
          </button>
          <button
            className="p-3 mx-auto block bg-primaryBlack rounded-tl-none rounded-bl-none hover:bg-summerBlue text-white font-medium text-lg rounded w-full disabled:opacity-25"
            onClick={() => {
              if (isPublised) {
                saveAsDraft();
              } else {
                publish();
              }
            }}
            disabled={publishLoading || draftLoading}
          >
            Да
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default PublishPollModal;
