import React, { FC } from 'react';
import { Answer, Questions } from '../../utils/types';
import PlusAns from '../../assets/images/plus_ans.png';
import Remove from '../../assets/images/remove.png';
import { DebounceInput } from 'react-debounce-input';
import cryptoRandomString from 'crypto-random-string';
import {
  useRemoveAnswerMutation,
  useRemoveQuestionMutation,
  useUpdateAnswerMutation,
  useUpdateQuestionMutation,
} from '../../generated/apolloComponents';
type QuestionBuilderProps = {
  questions: Questions | null | undefined;
  refetch: () => void;
  pollId: string;
};

const QuestionBuilder: FC<QuestionBuilderProps> = ({
  questions,
  refetch,
  pollId,
}: QuestionBuilderProps) => {
  const [updateAnswer] = useUpdateAnswerMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [updateQuestion] = useUpdateQuestionMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [removeAnswer] = useRemoveAnswerMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [removeQuestion] = useRemoveQuestionMutation({
    onCompleted: () => {
      refetch();
    },
  });
  if (questions === undefined) {
    return <div>{''}</div>;
  }

  const addNewAnswer = async (questionId: string) => {
    const answer: Answer = {
      id: cryptoRandomString({ length: 10 }),
      name: 'Цъкни тук за да промениш',
      value: 'click',
    };
    await updateAnswer({
      variables: {
        data: {
          questionId,
          answer,
        },
      },
    });
    refetch();
  };

  return (
    <>
      {questions &&
        questions.map((q, index) => (
          <div
            key={index}
            className="bg-white flex mt-6 flex-col my-2 max-w-5xl mx-auto relative rounded"
          >
            <div className="mx-12 mt-6 mb-6">
              <div key={q.id}>
                <h3 className="text-left text-3xl font-bold flex">
                  {q.isRequired && <span className="text-red-400">*</span>}
                  <DebounceInput
                    minLength={2}
                    value={q.questionName}
                    debounceTimeout={1000}
                    onChange={(event) =>
                      updateQuestion({
                        variables: {
                          data: {
                            pollId,
                            question: {
                              id: q.id,
                              questionName: event.target.value,
                              typeAnswer: q.typeAnswer,
                              isRequired: q.isRequired,
                            },
                          },
                        },
                      })
                    }
                  />
                  {q.typeAnswer === 'checkBox' && (
                    <button className="btn text-white py-1 px-1 ml-3 cursor-pointer">
                      <img
                        src={PlusAns}
                        onClick={() => addNewAnswer(q.id)}
                        alt="Success"
                        className="mx-auto my-auto cursor-pointer"
                      />
                    </button>
                  )}
                  {q.typeAnswer === 'radioBox' && (
                    <button className="btn text-white py-1 px-1 ml-3 cursor-pointer">
                      <img
                        src={PlusAns}
                        onClick={() => addNewAnswer(q.id)}
                        alt="Success"
                        className="mx-auto my-auto cursor-pointer"
                      />
                    </button>
                  )}
                  <button className="btn text-white py-1 px-1 ml-1 cursor-pointer">
                    <img
                      src={Remove}
                      onClick={() =>
                        removeQuestion({ variables: { questionId: q.id } })
                      }
                      alt="Success"
                      className="mx-auto my-auto cursor-pointer"
                    />
                  </button>
                </h3>
                <div className="mt-3">
                  {q.typeAnswer === 'radioBox' &&
                    q.answers.map((ans, radioIndex) => (
                      <label
                        key={radioIndex}
                        className="flex justify-start items-start mt-1 mb-1"
                      >
                        <svg
                          onClick={() =>
                            removeAnswer({
                              variables: { answerId: ans.id },
                            })
                          }
                          className="bg-white w-4 h-6 flex flex-shrink-0 justify-center items-center mr-4"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.293031 1.29296C0.480558 1.10549 0.734866 1.00017 1.00003 1.00017C1.26519 1.00017 1.5195 1.10549 1.70703 1.29296L6.00003 5.58596L10.293 1.29296C10.3853 1.19745 10.4956 1.12127 10.6176 1.06886C10.7396 1.01645 10.8709 0.988862 11.0036 0.987709C11.1364 0.986555 11.2681 1.01186 11.391 1.06214C11.5139 1.11242 11.6255 1.18667 11.7194 1.28056C11.8133 1.37446 11.8876 1.48611 11.9379 1.60901C11.9881 1.7319 12.0134 1.86358 12.0123 1.99636C12.0111 2.12914 11.9835 2.26036 11.9311 2.38236C11.8787 2.50437 11.8025 2.61471 11.707 2.70696L7.41403 6.99996L11.707 11.293C11.8892 11.4816 11.99 11.7342 11.9877 11.9964C11.9854 12.2586 11.8803 12.5094 11.6948 12.6948C11.5094 12.8802 11.2586 12.9854 10.9964 12.9876C10.7342 12.9899 10.4816 12.8891 10.293 12.707L6.00003 8.41396L1.70703 12.707C1.51843 12.8891 1.26583 12.9899 1.00363 12.9876C0.741432 12.9854 0.49062 12.8802 0.305212 12.6948C0.119804 12.5094 0.0146347 12.2586 0.0123563 11.9964C0.0100779 11.7342 0.110873 11.4816 0.293031 11.293L4.58603 6.99996L0.293031 2.70696C0.10556 2.51943 0.000244141 2.26512 0.000244141 1.99996C0.000244141 1.73479 0.10556 1.48049 0.293031 1.29296Z"
                            fill="#787878"
                          />
                        </svg>
                        <div className="bg-white border border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                          <input
                            type="radio"
                            name={q.id}
                            value={ans.value}
                            className="opacity-0 absolute"
                          />
                          <svg
                            className="bg-summerBlue fill-current text-white hidden w-6 h-6 p-1 pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div className="select-none">
                          <DebounceInput
                            minLength={2}
                            value={ans.name}
                            debounceTimeout={1000}
                            onChange={(e) =>
                              updateAnswer({
                                variables: {
                                  data: {
                                    answer: {
                                      name: e.target.value,
                                      value: e.target.value,
                                      id: ans.id,
                                    },
                                    questionId: q.id,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      </label>
                    ))}
                  {q.typeAnswer === 'textInput' &&
                    q.answers.map((ans, textIndex) => (
                      <div className="px-3 mb-4" key={textIndex}>
                        <textarea
                          disabled
                          name={ans.name}
                          value="Поле за свободен отговор"
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                          placeholder={ans.name}
                        />
                      </div>
                    ))}
                  {q.typeAnswer === 'checkBox' &&
                    q.answers.map((ans, checkIndex) => (
                      <label
                        key={checkIndex}
                        className="flex justify-start items-start mt-1 mb-1"
                      >
                        <svg
                          onClick={() =>
                            removeAnswer({
                              variables: { answerId: ans.id },
                            })
                          }
                          className="bg-white w-4 h-6 flex flex-shrink-0 justify-center items-center mr-4"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.293031 1.29296C0.480558 1.10549 0.734866 1.00017 1.00003 1.00017C1.26519 1.00017 1.5195 1.10549 1.70703 1.29296L6.00003 5.58596L10.293 1.29296C10.3853 1.19745 10.4956 1.12127 10.6176 1.06886C10.7396 1.01645 10.8709 0.988862 11.0036 0.987709C11.1364 0.986555 11.2681 1.01186 11.391 1.06214C11.5139 1.11242 11.6255 1.18667 11.7194 1.28056C11.8133 1.37446 11.8876 1.48611 11.9379 1.60901C11.9881 1.7319 12.0134 1.86358 12.0123 1.99636C12.0111 2.12914 11.9835 2.26036 11.9311 2.38236C11.8787 2.50437 11.8025 2.61471 11.707 2.70696L7.41403 6.99996L11.707 11.293C11.8892 11.4816 11.99 11.7342 11.9877 11.9964C11.9854 12.2586 11.8803 12.5094 11.6948 12.6948C11.5094 12.8802 11.2586 12.9854 10.9964 12.9876C10.7342 12.9899 10.4816 12.8891 10.293 12.707L6.00003 8.41396L1.70703 12.707C1.51843 12.8891 1.26583 12.9899 1.00363 12.9876C0.741432 12.9854 0.49062 12.8802 0.305212 12.6948C0.119804 12.5094 0.0146347 12.2586 0.0123563 11.9964C0.0100779 11.7342 0.110873 11.4816 0.293031 11.293L4.58603 6.99996L0.293031 2.70696C0.10556 2.51943 0.000244141 2.26512 0.000244141 1.99996C0.000244141 1.73479 0.10556 1.48049 0.293031 1.29296Z"
                            fill="#787878"
                          />
                        </svg>
                        <div className="bg-white border border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                          <input
                            type="checkbox"
                            name={ans.name}
                            value={ans.value}
                            className="opacity-0 absolute"
                          />
                          <svg
                            className="bg-summerBlue fill-current text-white hidden w-6 h-6 p-1 pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div className="select-none">
                          <DebounceInput
                            minLength={2}
                            value={ans.name}
                            debounceTimeout={1000}
                            onChange={(e) =>
                              updateAnswer({
                                variables: {
                                  data: {
                                    answer: {
                                      name: e.target.value,
                                      value: e.target.value,
                                      id: ans.id,
                                    },
                                    questionId: q.id,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default QuestionBuilder;
