import React, { FC } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGetAllVotersQuery } from '../../generated/apolloComponents';
const Voters: FC = () => {
  const { data: voters } = useGetAllVotersQuery();
  return (
    <Sidebar>
      <div>
        <h1 className="text-3xl font-bold">Потребители</h1>
        <div className="my-2 flex sm:flex-row flex-col"></div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Имена
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Имейл
                  </th>
                </tr>
              </thead>
              <tbody>
                {voters?.getAllVoters.map((v, index) => (
                  <tr key={index} id={v.email}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {v.firstName} {v.lastName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {v.email}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Voters;
