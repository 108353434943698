import React, { FC, useState } from 'react';
import Plus from '../../assets/images/plus.png';
import Publish from '../../assets/images/publish.png';
import Edit from '../../assets/images/edit.png';
import Sidebar from '../../components/Sidebar/Sidebar';
import NewQuestionModal from '../../components/NewQuestionModal/NewQuestionModal';
import QuestionBuilder from '../../components/QuestionBuilder/QuestionBuilder';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPollQuery } from '../../generated/apolloComponents';
import Loading from '../../components/Loading/Loading';
import PublishPollModal from '../../components/PublishPollModal/PublishPollModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Params = {
  slug: string;
};
const PollCreator: FC = () => {
  const history = useNavigate();
  const { slug } = useParams<Params>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const publishedNotify = () => {
    toast.success('Успешно публикувана анкета!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };
  const draftedNotify = () => {
    toast.success('Анкетата е запазена като чернова!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };
  const {
    data: poll,
    loading,
    refetch,
  } = useGetPollQuery({
    variables: {
      slug: String(slug),
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (poll?.getPoll === undefined) {
    history('/');
  }
  return (
    <Sidebar>
      {poll?.getPoll && (
        <>
          <div className="bg-white flex mt-24 flex-col my-2 max-w-5xl mx-auto relative rounded">
            <div className="absolute top-0 right-0 w-24 h-24 flex items-center justify-center bg-green-500 float-right  rounded-tr rounded-bl">
              <img src={Edit} alt="Success" className="mx-auto my-auto" />
            </div>
            <div className="mx-12 mt-12 mb-10">
              <p className="text-left text-base text-gray-500">Редактиране</p>
              <h1 className="text-left text-5xl font-bold leading-none break-words max-w-2xl">
                {poll?.getPoll.name}
              </h1>
            </div>
          </div>
          <QuestionBuilder
            pollId={poll.getPoll.id}
            refetch={refetch}
            questions={poll.getPoll.questions}
          />
          <button
            className="btn bg-primaryBlack hover:bg-summerBlue hover:shadow-lg text-white fixed bottom-0 right-0 mb-10 mr-10 py-4 px-4"
            onClick={() => setIsOpen(true)}
          >
            <img src={Plus} alt="Success" className="mx-auto my-auto" />
          </button>
          <button
            className="btn bg-green-500 hover:bg-summerBlue hover:shadow-lg text-white fixed bottom-0 right-0 mb-10 mr-32 py-4 px-4"
            onClick={() => setPublishModal(true)}
          >
            <img src={Publish} alt="Publish" className="mx-auto my-auto" />
          </button>
          <NewQuestionModal
            refetch={refetch}
            modalIsOpen={isOpen}
            setIsOpen={setIsOpen}
            pollId={poll.getPoll.id}
          />
          <PublishPollModal
            isPublised={poll.getPoll.isPublished}
            published={publishedNotify}
            drafted={draftedNotify}
            refetch={refetch}
            modalIsOpen={publishModal}
            setIsOpen={setPublishModal}
            pollId={poll.getPoll.id}
          />
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </Sidebar>
  );
};

export default PollCreator;
