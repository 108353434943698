import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Polls from '../pages/Polls/Polls';
import Login from '../pages/Login/Login';
import Administrators from '../pages/Administrators/Administrators';
import NewPoll from '../pages/Polls/NewPoll';
import PollCreator from '../pages/Polls/PollCreator';
import PollStatistics from '../pages/Polls/PollStatistics';
import PrivateRoute from './PrivateRoute';
import Home from 'src/pages/Home/Home';
import Voters from 'src/pages/Users/Users';
import Members from 'src/pages/Members/Members';
const Routers: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/polls"
          element={
            <PrivateRoute>
              <Polls />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Voters />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-poll"
          element={
            <PrivateRoute>
              <NewPoll />
            </PrivateRoute>
          }
        />
        <Route
          path="/poll-creator/:slug"
          element={
            <PrivateRoute>
              <PollCreator />
            </PrivateRoute>
          }
        />
        <Route
          path="/poll-statistics/:slug"
          element={
            <PrivateRoute>
              <PollStatistics />
            </PrivateRoute>
          }
        />
        <Route
          path="/admins"
          element={
            <PrivateRoute>
              <Administrators />
            </PrivateRoute>
          }
        />
        <Route
          path="/members"
          element={
            <PrivateRoute>
              <Members />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Login />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default Routers;
