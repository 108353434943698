import React from 'react';
import Lock from '../../assets/images/lock-form.png';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import {
  LoginMutationVariables,
  useLoginMutation,
} from '../../generated/apolloComponents';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
const Login: React.FC = () => {
  const { register, handleSubmit } = useForm<LoginMutationVariables>();
  const history = useNavigate();
  const [loginUser, { loading }] = useLoginMutation();
  const onSubmit = (data: LoginMutationVariables) => {
    loginUser({
      variables: {
        ...data,
      },
      onCompleted: () => {
        console.log('test');
        history('/home');
      },
    });
  };
  return (
    <div className="overflow-x-hidden h-screen  align-middle">
      <div className="bg-lightGray mt-24 flex flex-col my-2 max-w-xl mx-auto relative">
        <div className="absolute top-0 right-0 w-24 h-24 flex items-center justify-center bg-summerBlue float-right">
          {loading ? (
            <ReactLoading
              type="spin"
              color="#ffffff"
              height={'30px'}
              width={'30px'}
            />
          ) : (
            <img src={Lock} alt="Lock" className="mx-auto my-auto" />
          )}
        </div>
        <div className="mx-12 mt-12 mb-10">
          <h1 className="text-left text-6xl font-bold">Вход</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-3 mb-4 mt-10">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Имейл
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                id="grid-first-name"
                type="text"
                placeholder="Имейл"
                {...register('email', {
                  required: true,
                })}
              />
            </div>
            <div className="px-3 mb-4 mt-10">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                Парола
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                type="password"
                placeholder="Парола"
                {...register('password', {
                  required: true,
                })}
              />
            </div>
            <button
              className="w-full bg-primaryBlack py-3 font-bold text-lg disabled:opacity-25 text-white mt-10"
              type="submit"
            >
              Вход
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
