import React from 'react';
import Apollo from './Apollo';
import './index.css';

import ReactDOM from 'react-dom/client';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Apollo />
  </React.StrictMode>
);
