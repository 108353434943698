import React, { FC, useState } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGetAllUsersQuery, User } from '../../generated/apolloComponents';
import Block from '../../assets/images/block.png';
import BlockAdminModal from '../../components/BlockAdminModal/BlockAdminModal';
const Administrators: FC = () => {
  const { data: admins, refetch } = useGetAllUsersQuery();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentAdmin, setCurrentAdmin] = useState<
    Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'status'>
  >({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    status: false,
  });
  const [search, setSearch] = useState<string>('');
  return (
    <Sidebar>
      <div>
        <h1 className="text-3xl font-bold">Администратори</h1>
        <div className="my-2 flex sm:flex-row flex-col">
          <div className="block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                viewBox="0 0 24 24"
                className="h-4 w-4 fill-current text-gray-500"
              >
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
              </svg>
            </span>
            <input
              placeholder="Търси"
              className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Име
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Телефон
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Имейл
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Статус
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Действия
                  </th>
                </tr>
              </thead>
              <tbody>
                {admins &&
                  admins.getAllUsers
                    .filter(
                      ({ firstName, lastName }) =>
                        firstName.toLowerCase().indexOf(search.toLowerCase()) >
                          -1 ||
                        lastName.toLowerCase().indexOf(search.toLowerCase()) >
                          -1
                    )
                    .map((admin) => (
                      <tr key={admin.id}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {admin.firstName} {admin.lastName}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {admin.phone}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {admin.email}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {admin.status ? (
                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                              ></span>
                              <span className="relative">Активен</span>
                            </span>
                          ) : (
                            <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                              ></span>
                              <span className="relative">Неактивен</span>
                            </span>
                          )}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10 m-1">
                              <img
                                src={Block}
                                onClick={() => {
                                  setCurrentAdmin(admin);
                                  setIsOpen(true);
                                }}
                                alt="Edit Poll"
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <BlockAdminModal
        modalIsOpen={isOpen}
        setIsOpen={setIsOpen}
        admin={currentAdmin}
        refetch={refetch}
      />
    </Sidebar>
  );
};

export default Administrators;
