import React, { FC, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../assets/icons/logo.svg';
import { useLogoutMutation } from '../../generated/apolloComponents';
import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';
type Props = {
  children?: React.ReactNode;
};
const Sidebar: FC<Props> = ({ children }: Props) => {
  const history = useNavigate();
  const [logOut, { loading }] = useLogoutMutation({
    onCompleted: () => history('/login'),
  });
  const [pollsDropdown, setPollsDropdown] = useState<boolean>(false);

  if (loading) return <Loading />;
  return (
    <>
      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full bg-gray-200">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white flex-shrink-0">
          <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-center">
            <Link to="/home">
              <LogoSVG />
            </Link>
          </div>
          <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                  : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
              }
            >
              Табло
            </NavLink>
            <div className="relative">
              <button
                className="flex flex-row items-center  w-full px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                onClick={() => setPollsDropdown(!pollsDropdown)}
              >
                <span>Анкети</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className={`inline w-4 h-4 mt-1 ml-1 md:-mt-1 ${
                    pollsDropdown ? 'transform rotate-180' : ''
                  }`}
                >
                  $
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              {pollsDropdown && (
                <>
                  <div className="px-2 py-2">
                    <NavLink
                      to="/polls"
                      className={({ isActive }) =>
                        isActive
                          ? 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                          : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                      }
                    >
                      Всички анкети
                    </NavLink>
                  </div>
                </>
              )}
            </div>
            <NavLink
              to="/users"
              className={({ isActive }) =>
                isActive
                  ? 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                  : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
              }
            >
              Потребители
            </NavLink>
            <NavLink
              to="/admins"
              className={({ isActive }) =>
                isActive
                  ? 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                  : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
              }
            >
              Администратори
            </NavLink>
            <NavLink
              to="/members"
              className={({ isActive }) =>
                isActive
                  ? 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                  : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
              }
            >
              Общност
            </NavLink>
            <button
              onClick={() => logOut()}
              className="w-full text-left block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-white rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            >
              Изход
            </button>
          </nav>
          <Footer />
        </div>
        <main className="m-10 w-full">{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
