import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import cryptoRandomString from 'crypto-random-string';
import { Question } from '../../utils/types';
import {
  useUpdateAnswerMutation,
  useUpdateQuestionMutation,
} from '../../generated/apolloComponents';
type NewQuestionModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  pollId: string;
  refetch: () => void;
};

const NewQuestionModal: FC<NewQuestionModalProps> = ({
  modalIsOpen,
  setIsOpen,
  pollId,
  refetch,
}: NewQuestionModalProps) => {
  const { register, handleSubmit } = useForm<Question>();

  const [updateQuestion] = useUpdateQuestionMutation();
  const [updateAnswer] = useUpdateAnswerMutation();
  const [isRequired, setIsRequired] = useState<boolean>(true);
  const newQuestion = async (data: Question) => {
    data.id = cryptoRandomString({ length: 10 });
    const answer = {
      id: cryptoRandomString({ length: 10 }),
      name: 'Цъкни тук за да промениш',
      value: 'click',
    };
    await updateQuestion({
      variables: {
        data: {
          question: {
            id: data.id,
            questionName: data.questionName,
            typeAnswer: data.typeAnswer,
            isRequired: data.isRequired ? true : false,
          },
          pollId: pollId,
        },
      },
    });

    await updateAnswer({
      variables: {
        data: {
          questionId: data.id,
          answer: answer,
        },
      },
    });
    refetch();
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      className="NewQuestionModal"
      overlayClassName="NewQuestionModalOverlay"
    >
      <div className="relative flex justify-center py-3 bg-gray-200 rounded-t">
        <span className="text-md mr-5 sm:mr-0 md:text-xl text-center sm:text-left">
          Добави нов въпрос
        </span>
        <CloseIcon
          onClick={() => setIsOpen(false)}
          className="absolute right-5 top-15 cursor-pointer"
        />
      </div>
      <div className="p-2 sm:p-6">
        <form onSubmit={handleSubmit(newQuestion)}>
          <div className="mb-2">
            <span className="font-medium text-gray-700">Въпрос:</span>
            <input
              className="mt-1 appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-900 pb-3"
              type="text"
              placeholder="На каква възраст сте?"
              {...register('questionName', {
                required: 'Полето е задължително!',
              })}
            />
          </div>
          <span className="font-medium text-gray-700">Тип на отговор:</span>
          <div className="relative flex flex-row items-center justify-end mt-1 mb-2">
            <select
              className="mt-1 appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-900 pb-3"
              defaultValue={''}
              {...register('typeAnswer', {
                required: 'Полето е задължително!',
              })}
              onChange={(e) => {
                if (e.target.value === 'checkBox') setIsRequired(false);
              }}
            >
              <option>Тип отговор</option>
              <option value="textInput">Свободно поле</option>
              <option value="radioBox">Единичен избор</option>
              <option value="checkBox">Множествен избор</option>
              <option value="file">Файл</option>
            </select>
            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
              <svg
                className="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div className={`${isRequired ? `` : `hidden`}`}>
            <span className="font-medium text-gray-700">
              Задължителен ли е?
            </span>
            <div className="relative flex flex-row items-center justify-end mt-1">
              <select
                className="mt-1 appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-900 pb-3"
                {...register('isRequired', {
                  required: 'Полето е задължително!',
                })}
                defaultValue={'no'}
              >
                <option value="0">Не</option>
                <option value="1">Да</option>
              </select>
              <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                <svg
                  className="h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn p-3  mt-8 mb-6 sm:mb-0 mx-auto block bg-primaryBlack  disabled:opacity-25 text-white font-medium text-lg rounded"
          >
            Добави
          </button>
        </form>
      </div>
    </Modal>
  );
};
export default NewQuestionModal;
