import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useCreateFolderLazyQuery,
  useSignRequestLazyQuery,
  useUpdateMemberMutation,
  useRemoveFilesMutation,
} from '../../generated/apolloComponents';

type MemberModalProps = {
  handleClose: () => void;
  refetch: () => void;
  isEdit: boolean;
  memberData?: any;
};

const MemberModal = ({
  handleClose,
  refetch,
  isEdit,
  memberData,
}: MemberModalProps) => {
  const { register, setValue, handleSubmit } = useForm({});
  const [createFolder] = useCreateFolderLazyQuery();
  const [signRequest] = useSignRequestLazyQuery();
  const [updateMember] = useUpdateMemberMutation();
  const [removeFiles] = useRemoveFilesMutation();
  const [isBoardMember, setIsBoardMember] = useState<boolean>(false);
  const [memberImg, setMemberImg] = useState<string | undefined>();
  const [logoImg, setLogoImg] = useState<Array<string | undefined>>([]);
  const [filesToDelete, setFilesToDelete] = useState<Array<string | undefined>>(
    []
  );
  const [imgToUpload, setImgToUpload] = useState<Array<File | undefined>>([]);
  const [logosToUpload, setLogosToUpload] = useState<Array<File | undefined>>(
    []
  );

  useEffect(() => {
    if (isEdit && memberData) {
      const fields = Object.keys(memberData);
      fields.forEach((field) => setValue(field, memberData[field]));
      setIsBoardMember(memberData.isBoard);
      setMemberImg(memberData['imgUrl']);
      setLogoImg(memberData['logos']);
    }
    if (!isEdit) {
      setValue('isBoard', false);
    }
  }, [memberData]);

  const handleDeleteImg = () => {
    setFilesToDelete((current) => [...current, memberImg]);
    setMemberImg(undefined);
  };

  const handleDeleteLogos = () => {
    setFilesToDelete((current) => [...current, ...logoImg]);
    setLogoImg([]);
  };

  const handleImgFileUpload = (e: any) => {
    const { id, files } = e.target;
    const filesArray = Array.from(files) as Array<File>;
    if (id === 'imgUrl') {
      setImgToUpload(filesArray);
    } else if (id === 'logos') {
      setLogosToUpload(filesArray);
    }
  };

  const handleProfileImg = async (folderName: string, data: any) => {
    if (imgToUpload.length > 0) {
      const formData = new FormData();
      //@ts-ignore
      formData.append('file', imgToUpload[0]);
      formData.append('folder', folderName);
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dpm9qj26o/image/upload/?api_key=339627851882791&timestamp=${data?.signRequest.timestamp}&signature=${data?.signRequest.signature}`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const { secure_url } = await response.json();
      return secure_url;
    }
  };

  const handleLogoImg = async (folderName: string, data: any) => {
    const logosUrls: string[] = [];
    if (logosToUpload.length > 0) {
      const formData = new FormData();
      for (const file of logosToUpload) {
        //@ts-ignore
        formData.append('file', file);
        formData.append('folder', folderName);
        const res = await fetch(
          `https://api.cloudinary.com/v1_1/dpm9qj26o/image/upload/?api_key=339627851882791&timestamp=${data?.signRequest.timestamp}&signature=${data?.signRequest.signature}`,
          {
            method: 'POST',
            body: formData,
          }
        );
        const { secure_url } = await res.json();
        logosUrls.push(secure_url);
      }
    }
    return logosUrls;
  };

  const handleEditMember = async (memberData: any) => {
    const finalResult: any = {};
    const folderName = `Members/${memberData.firstName}_${memberData.lastName}`;

    const { data } = await signRequest({
      variables: {
        params: { folder: folderName },
      },
    });

    const profileImg = await handleProfileImg(folderName, data);
    if (profileImg) finalResult.imgUrl = profileImg;

    const logoImages = await handleLogoImg(folderName, data);
    finalResult.logos = logoImages;

    return finalResult;
  };

  const handleAddMember = async (data: any) => {
    const folderName = `Members/${data.firstName}_${data.lastName}`;
    const finalResult: any = {};
    const folderCreated = await createFolder({
      variables: {
        folderName: folderName,
      },
    });

    const signedRequest = await signRequest({
      variables: {
        params: { folder: folderCreated.data?.createFolder.path },
      },
    });

    const profileImg = await handleProfileImg(folderName, signedRequest.data);
    if (profileImg) finalResult.imgUrl = profileImg;

    const logoImages = await handleLogoImg(folderName, signedRequest.data);
    finalResult.logos = logoImages;

    return finalResult;
  };

  const submitMember = async (data: any) => {
    const result = isEdit
      ? await handleEditMember(data)
      : await handleAddMember(data);
    if (imgToUpload.length > 0 && result.imgUrl) {
      data.imgUrl = result.imgUrl;
    }

    if (logosToUpload.length > 0 && result.logos && result.logos.length > 0) {
      data.logos = result.logos;
    }

    const { __typename, ...rest } = data;
    updateMember({
      variables: { data: { member: rest } },
      onCompleted: async () => {
        if (filesToDelete.length > 0) {
          const ids = [];
          const publicIDRegex = /([^\/]+)(?=\.\w+$)/;
          const folderName = `Members/${data.firstName}_${data.lastName}`;
          for (const file of filesToDelete) {
            const matching = file?.match(publicIDRegex);
            if (matching && matching[0])
              ids.push(`${folderName}/${matching[0]}`);
          }
          await removeFiles({
            variables: {
              publicIds: ids,
            },
          });
          setFilesToDelete([]);
        }
        handleClose();
        refetch();
      },
    });
  };
  return (
    <div>
      <div>
        <form
          className="relative bg-white rounded-lg shadow"
          onSubmit={handleSubmit(submitMember)}
        >
          <div className="flex items-start justify-between p-4 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">
              {isEdit ? 'Коригирай' : 'Добави член'}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleClose}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Име
                </label>
                <input
                  type="text"
                  id="first-name"
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brandOrange focus:border-brandOrange block w-full p-2.5"
                  placeholder="Viktoria"
                  {...register('firstName', {
                    required: 'Полето е задължително!',
                  })}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Фамилия
                </label>
                <input
                  type="text"
                  id="last-name"
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  placeholder="Karoleva"
                  {...register('lastName', {
                    required: 'Полето е задължително!',
                  })}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Позиция/Роля
                </label>
                <input
                  type="text"
                  id="role"
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  placeholder="CEO"
                  {...register('role', {
                    required: 'Полето е задължително!',
                  })}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="company"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Компания
                </label>
                <input
                  type="text"
                  {...register('company', {
                    required: 'Полето е задължително!',
                  })}
                  id="company"
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  placeholder="FAME"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="department"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Категория
                </label>
                <select
                  id="memberCategory"
                  {...register('memberCategory', {
                    required: 'Полето е задължително!',
                  })}
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  placeholder="founder"
                >
                  <option value="founder">Founder</option>
                  <option value="mentor">Mentor</option>
                  <option value="angel">Angel</option>
                  <option value="explorer">Explorer</option>
                  <option value="board">Board</option>
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="company"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Кратко описание
                </label>
                <input
                  type="text"
                  id="shortDesc"
                  {...register('shortDesc', {
                    required: 'Полето е задължително!',
                  })}
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                />
              </div>
              {memberImg ? (
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="current-password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Профилна снимка
                  </label>
                  <div className="flex items-center justify-between">
                    <input
                      type="input"
                      id="profile-pic"
                      disabled
                      value={memberImg}
                      className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                    />
                    <button onClick={handleDeleteImg}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="current-password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Профилна снимка
                  </label>
                  <input
                    type="file"
                    id="imgUrl"
                    onChange={(e) => handleImgFileUpload(e)}
                    className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  />
                </div>
              )}
              {logoImg.length > 0 ? (
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="current-password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Лого / Допълнителни изображения
                  </label>
                  <div className="flex items-center justify-between">
                    <input
                      type="input"
                      id="logosEdit"
                      disabled
                      value={logoImg.join(',')}
                      className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                    />
                    <button onClick={handleDeleteLogos}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="current-password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Лого / Допълнителни изображения
                  </label>
                  <input
                    type="file"
                    id="logos"
                    multiple
                    onChange={(e) => handleImgFileUpload(e)}
                    className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  />
                </div>
              )}
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="current-password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Дълго описание
                </label>
                <textarea
                  id="longDesc"
                  rows={8}
                  className="shadow-sm bg-gray-50 border outline-brandOrange border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5"
                  {...register('longDesc', {
                    required: 'Полето е задължително!',
                  })}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <input
                  type="checkbox"
                  checked={isBoardMember}
                  onChange={(e) => {
                    setValue('isBoard', e.target.checked ? true : false);
                    setIsBoardMember(!isBoardMember);
                  }}
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Борд мембър
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
            <button
              type="submit"
              className="text-white bg-orange-400 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Save all
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MemberModal;
