import React, { FC } from 'react';
import Voters from '../../assets/images/voters.png';
import ActivePolls from '../../assets/images/polls.png';
import Lock from '../../assets/images/lock.png';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGetHomeDataQuery } from 'src/generated/apolloComponents';

const Home: FC = () => {
  const { data } = useGetHomeDataQuery();
  return (
    <Sidebar>
      <div>
        <h1 className="text-3xl font-bold">Табло</h1>
        <div className="mt-4">
          <div className="flex flex-wrap -mx-6">
            <div className="w-full px-6 sm:w-1/2 xl:w-1/3">
              <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                <div className="p-3 rounded-full bg-summerBlue bg-opacity-75">
                  <img src={Voters} alt="voters" />
                </div>

                <div className="mx-5">
                  <h4 className="text-2xl font-semibold text-gray-700">
                    {data?.homeData.countActiveVoters}
                  </h4>
                  <div className="text-gray-500">Регистрирани потребители</div>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
              <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                <div className="p-3 rounded-full  bg-primaryBlack bg-opacity-75">
                  <img src={ActivePolls} alt="activePolls" />
                </div>

                <div className="mx-5">
                  <h4 className="text-2xl font-semibold text-gray-700">
                    {data?.homeData.countActivePolls}
                  </h4>
                  <div className="text-gray-500">Актвни анкети</div>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
              <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                <div className="p-3 rounded-full  bg-summerBlue bg-opacity-75">
                  <img src={Lock} alt="lock" />
                </div>

                <div className="mx-5">
                  <h4 className="text-2xl font-semibold text-gray-700">
                    {data?.homeData.countAnswers}
                  </h4>
                  <div className="text-gray-500">Отговора</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Home;
