import React, { FC, useState } from 'react';
import Statistics from '../../assets/images/statistics-white.png';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useDownloadVotesLazyQuery,
  useDownloadVotesQuery,
  useGetPollQuery,
} from '../../generated/apolloComponents';
import Loading from '../../components/Loading/Loading';
import { CSVLink } from 'react-csv';

type Params = {
  slug: string;
};
const PollStatistics: FC = () => {
  const history = useNavigate();
  const [filteredData, setFilteredData] = useState<any>([]);
  const { slug } = useParams<Params>();
  const { data: poll, loading } = useGetPollQuery({
    variables: {
      slug: String(slug),
    },
  });
  const { data: downloadData, loading: downloadLoading } =
    useDownloadVotesQuery({
      variables: { pollId: String(poll?.getPoll.id) },
    });

  const [ansData] = useDownloadVotesLazyQuery();

  if (loading || downloadLoading) {
    return <Loading />;
  }
  if (poll?.getPoll === undefined) {
    history('/');
  }
  return (
    <Sidebar>
      {poll?.getPoll &&
        downloadData?.downloadVotes &&
        downloadData?.downloadVotes.votes && (
          <div className="max-w-5xl">
            <div className="bg-white flex  flex-col my-2 max-w-5xl mx-auto relative rounded">
              <div className="absolute top-0 right-0 w-24 h-24 flex items-center justify-center bg-green-500 float-right  rounded-tr rounded-bl">
                <img
                  src={Statistics}
                  alt="Success"
                  className="mx-auto my-auto"
                />
              </div>
              <div className="mx-12 mt-12 mb-10">
                <p className="text-left text-base text-gray-500">Отговори</p>
                <h1 className="text-left text-5xl font-bold leading-none break-words max-w-2xl">
                  {poll?.getPoll.name}
                </h1>
              </div>
              <CSVLink
                className="bg-primaryBlack text-white px-4 py-2 rounded"
                data={filteredData || []}
                separator={'|'}
                asyncOnClick={true}
                filename={`${poll?.getPoll.slug}.csv`}
                onClick={(_event, done) => {
                  ansData({
                    variables: { pollId: String(poll?.getPoll.id) },
                    onCompleted: (data) => {
                      data.downloadVotes.votes?.forEach((vote) => {
                        setFilteredData((prevState: any) => [
                          ...prevState,
                          {
                            firstName: vote.voter.firstName,
                            lastName: vote.voter.lastName,
                            email: vote.voter.email,
                            question: vote.question.questionName,
                            answer: vote.answerValue,
                          },
                        ]);
                      });
                      done();
                    },
                  });
                }}
              >
                Свали
              </CSVLink>
            </div>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Потребител
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Въпрос
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Отговор
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {downloadData?.downloadVotes?.votes.map((vote, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <Link to={`/users/#${vote.voter.email}`}>
                            <p className="text-gray-900 whitespace-no-wrap font-bold">
                              {vote.voter.firstName} {vote.voter.lastName}
                            </p>
                          </Link>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {vote.question.questionName}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {vote.answerValue}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
    </Sidebar>
  );
};

export default PollStatistics;
