import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Polls from '../../assets/images/polls.png';
import Sidebar from '../../components/Sidebar/Sidebar';
import {
  AddNewPollInput,
  useAddNewPollMutation,
  useSignRequestLazyQuery,
} from '../../generated/apolloComponents';
import InnerLoading from 'src/components/InnerLoading';
const NewPoll: FC = () => {
  const history = useNavigate();
  const { register, handleSubmit } = useForm<AddNewPollInput>();
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<string>>([]);
  const [signRequest] = useSignRequestLazyQuery();
  const [addNewPoll] = useAddNewPollMutation();
  const submit = (data: AddNewPollInput) => {
    addNewPoll({
      variables: {
        data: {
          name: data.name,
          desc: data.desc,
          color: data.color,
          progressColor: data.progressColor,
          imgSrc: files[0] || '',
        },
      },
      onCompleted: (data) => {
        history(`/poll-creator/${data.addNewPoll.slug}`);
      },
    });
  };

  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
  });

  useEffect(() => {
    console.log(files.length);
    if (acceptedFiles.length > 0 && !files.length) {
      setFileUploadLoading(true);
      signRequest({
        onCompleted: async (signRequestData) => {
          const formData = new FormData();
          acceptedFiles.forEach(async (file) => {
            formData.append('file', file);
            await fetch(
              `https://api.cloudinary.com/v1_1/dpm9qj26o/image/upload/?api_key=339627851882791&timestamp=${signRequestData?.signRequest.timestamp}&signature=${signRequestData?.signRequest.signature}`,
              {
                method: 'POST',
                body: formData,
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setFiles((current) => [...current, data.secure_url]);
                setFileUploadLoading(false);
              });
          });
        },
      });
    }
  }, [acceptedFiles]);

  return (
    <Sidebar>
      <div className="bg-white flex mt-24 flex-col my-2 max-w-xl mx-auto relative rounded">
        <div className="absolute top-0 right-0 w-24 h-24 flex items-center justify-center bg-green-500 float-right  rounded-tr rounded-bl">
          <img src={Polls} alt="Success" className="mx-auto my-auto" />
        </div>
        <div className="mx-12 mt-12 mb-10">
          <h1 className="text-left text-5xl font-bold leading-none">
            Нова
            <br />
            анкета
          </h1>
          <form className="mt-5" onSubmit={handleSubmit(submit)}>
            <div className="px-3 mb-4">
              <label className="block uppercase tracking-wide text-gray-darker text-xs font-bold mb-2">
                Име
              </label>
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-darker border border-red rounded py-3 px-4 mb-3"
                type="text"
                {...register('name', {
                  required: 'Името е задължително!',
                })}
                placeholder="Анкета 2"
              />
            </div>
            <div className="px-3 mb-4">
              <label className="block uppercase tracking-wide text-gray-darker text-xs font-bold mb-2">
                Кратко описание
              </label>
              <textarea
                {...register('desc', {
                  required: 'Краткото описание е задължително!',
                })}
                className="appearance-none block w-full bg-gray-100 text-gray-darker border border-red rounded py-3 px-4 mb-3"
                placeholder="Кратко описание"
              />
            </div>
            <div className="px-3 mb-4">
              <label className="block uppercase tracking-wide text-gray-darker text-xs font-bold mb-2">
                Цвят
              </label>
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-darker border border-red rounded py-3 px-4 mb-3"
                type="text"
                {...register('color', {
                  required: 'Цвета е задължително!',
                })}
                placeholder="#00000"
              />
            </div>
            <div className="px-3 mb-4">
              <label className="block uppercase tracking-wide text-gray-darker text-xs font-bold mb-2">
                Цвят на прогрес
              </label>
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-darker border border-red rounded py-3 px-4 mb-3"
                type="text"
                {...register('progressColor', {
                  required: 'Цвета е задължително!',
                })}
                placeholder="#00000"
              />
            </div>
            <div
              className="flex w-full flex-col items-center justify-center px-3 mb-4"
              {...getRootProps()}
              onClick={open}
            >
              <input {...getInputProps()} />
              <label className="flex h-auto w-full flex-col rounded border-2 border-dashed border-blue-200 transition duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-100">
                {fileUploadLoading ? (
                  <div className="my-7">
                    <InnerLoading />
                  </div>
                ) : (
                  <>
                    <div className="my-6 flex flex-col items-center justify-center">
                      <div className="flex flex-row justify-start space-x-4">
                        {files.map((image, index) => (
                          <div className="relative mb-7" key={index}>
                            <img
                              src={image}
                              alt="1"
                              className="h-16 w-auto object-contain rounded-md"
                            />
                            <div
                              className="absolute -top-1 -right-1 cursor-pointer rounded-full bg-red-500"
                              onClick={() => {
                                setFiles((current) =>
                                  current.filter((i) => i !== image)
                                );
                              }}
                            >
                              <span className="flex h-4 w-4 items-center justify-center text-xxs text-white">
                                <svg
                                  width="9"
                                  height="9"
                                  viewBox="0 0 9 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 8L1 1M8 1L1 8"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <svg
                        width="41"
                        height="34"
                        viewBox="0 0 41 34"
                        className="stroke-gray-400"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.7241 25.7241H31.6551C35.9472 25.7241 39.459 23.4445 39.459 19.1999C39.459 14.9554 35.3229 12.842 31.9672 12.6758C31.2735 6.03777 26.4264 2 20.7295 2C15.3448 2 11.8767 5.57344 10.7404 9.11722C6.05806 9.56204 2 12.5416 2 17.4206C2 22.2997 6.21414 25.7241 11.3648 25.7241H15.735"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.7295 32.0002V13.2379M25.7241 16.9838L20.7295 11.9893L15.735 16.9838H25.7241Z"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                        Attach files
                      </p>
                    </div>
                  </>
                )}
              </label>
            </div>
            <div className="flex justify-end items-center ml-3">
              <button
                className="bg-primaryBlack text-white px-4 py-2 text-lg rounded"
                type="submit"
              >
                Добави анкета
              </button>
            </div>
          </form>
        </div>
      </div>
    </Sidebar>
  );
};

export default NewPoll;
