import React from 'react';
import { Navigate } from 'react-router-dom';
import Loading from 'src/components/Loading/Loading';
import { useMeQuery } from 'src/generated/apolloComponents';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { data: auth, loading } = useMeQuery();
  if (loading) {
    return <Loading />;
  } else {
    return auth?.me.email ? children : <Navigate to="/login" replace />;
  }
};

export default PrivateRoute;
